import request from '@/utils/request';

// 获取首页推荐数据
export function getRecommend() {
  return request({
    url: `/whitelist/applications/recommend`,
    method: 'get'
  });
}

// 获取应用详情
export function getDetail(id) {
  return request({
    url: `/whitelist/applications/detail?id=` + id,
    method: 'get'
  });
}

// 获取应用分页列表
export function getAppList(pageNum, pageSize) {
  return request({
    url: `/whitelist/applications?pageNum=` + pageNum + '&pageSize=' + pageSize,
    method: 'get'
  });
}

// 下载
export function donwloadPakge() {
  return request({
    url: `/whitelist/campus/apps/link`,
    method: 'get'
  });
}
