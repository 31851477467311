<template>
  <div class="educationPlatform">
    <!-- PC端 -->
    <OtherBanner :banner-list="bannerListPc" :banner-list-mobile="bannerListMoblie" />
    <div class="eduIndex-pc">
      <div class="banner-remark">
        <p class="title">
          科学快乐，学习更有效
        </p>
        <p class="des">
          游戏与学科相融，抓住学生兴趣点，提供游戏化教学应用工具，让学生动中学、玩中学、巧中学
        </p>
      </div>
      <div class="tools-box-pc">
        <div v-if="viewData.length>0" class="line-title">
          <label>教学工具</label>
          <span @click="$router.push({path:'/educ/educTools'})">更多教学工具 <img src="../../assets/images/icon-more.png" class="moreImg"></span>
        </div>
        <div v-if="viewData.length>0" class="tools-list">
          <div v-for="(item,index) in viewData" :key="index" class="item">
            <div class="left">
              <div class="type-tag" :class="'trends'+item.terminalType">
                {{ appType[item.terminalType] }}
              </div>
              <img :src="item.appIconUrl" class="typeImg">
              <div class="title">
                {{ item.appName }}
              </div>
              <div class="remark">
                {{ item.introduction }}
              </div>
            </div>
            <div class="right">
              <img :src="item.homePagePictureUrl" class="remarkImg">
            </div>
          </div>
        </div>
        <NoneEmpty v-else />
        <div v-if="viewData.length>0" class="more-box" @click="$router.push({path:'/educ/educTools'})">
          更多教学工具 <img src="../../assets/images/icon-more.png" class="moreImg">
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="eduIndex-Mobile">
      <div class="mobile-one">
        <p class="title">
          优智多智慧教育生态
        </p>
        <div class="sub-title">
          游戏与学科相融，抓住学生兴趣点，提供游戏化教学应用工具，让学生动中学、玩中学、巧中学
        </div>
      </div>
      <div v-if="viewData.length>0" class="mobile-two">
        <span>教学工具</span>
        <div class="btnClick" @click="$router.push('/educ/educTools')">
          更多教学工具<img src="../../assets/images/icon-more.png" class="moreImg">
        </div>
      </div>
      <div v-if="viewData.length>0" class="mobile-three">
        <div v-for="(item,index) in viewData" :key="index" class="item">
          <div class="three-top-box">
            <img :src="item.appIconUrl" class="bigImgBox">
            <div class="right">
              <p class="title">
                {{ item.appName }}
              </p>
              <div class="type-btn" :class="'trends'+item.terminalType">
                {{ appType[item.terminalType] }}
              </div>
            </div>
          </div>
          <img :src="item.homePagePictureUrl" class="smallImgBox">
          <div class="desc">
            {{ item.introduction }}
          </div>
        </div>
      </div>
      <NoneEmpty v-else />
    </div>
  </div>
</template>
<script>
import OtherBanner from '@/components/baseComon/otherBanner.vue';
import NoneEmpty from '@/components/baseComon/noneEmpty.vue';
import { getRecommend } from '@/api/edu.js';
const appType = {
  '1': '联机应用',
  '2': '教师应用',
  '3': '学生应用',
  '4': '家校应用'
};
export default {
  name: 'EducationPlatform',
  components: { OtherBanner, NoneEmpty },
  data() {
    return {
      bannerListPc: [
        { imgUrl: require('../../assets/images/edu/edu-banner1.jpg') },
        { imgUrl: require('../../assets/images/edu/pc-2.jpg') },
        { imgUrl: require('../../assets/images/edu/pc-3.jpg') },
        { imgUrl: require('../../assets/images/edu/pc-4.jpg') }
      ],
      bannerListMoblie: [
        { imgUrl: require('../../assets/images/edu/edu-banner-mobile.png') },
        { imgUrl: require('../../assets/images/edu/mobile-2.jpg') },
        { imgUrl: require('../../assets/images/edu/mobile-3.jpg') },
        { imgUrl: require('../../assets/images/edu/mobile-4.jpg') }
      ],
      viewData: [],
      appType
    };
  },
  computed: {
  },
  mounted() {
    this.getRecommend();
  },
  methods: {
    getRecommend() {
      getRecommend().then(res => {
        this.viewData = res.data;
      });
    }

  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .educationPlatform{
    .eduIndex-Mobile{
      display: none;
    }
    .banner-remark{
      background: #fff;
      text-align: center;
      padding: 60px 0;
      .title{
        font-size: 44px;
        font-weight: bold;
        color: #333333;
      }
      .des{
        font-size: 24px;
        font-weight: 400;
        color: #FD9C02;
        margin-top: 15px;
      }
    }
    .tools-box-pc{
      width: 1200px;
      margin: 0 auto;
      .moreImg{
          width: 11px;
          height: 12px;
          vertical-align: middle;
          margin-left: 6px;
        }
      .line-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 60px 0 40px;
        font-weight: 400;
        label{
          font-size: 30px;
          color: #333333;
        }
        span{
          font-size: 16px;
          color: #666666;
          cursor: pointer;
        }

      }
      .tools-list{
        .item{
          display: flex;
          justify-content: flex-start;
          text-align: center;
          height: 514px;
          margin-bottom: 60px;
          &:last-child{
            margin-bottom: 30px;
          }
          .left{
            width: 400px;
            background: #F3F3F6;
            border-radius: 10px 0 0 10px;
            .type-tag{
              width: 96px;
              border-radius: 90px;
              font-size: 16px;
              font-weight: 400;
              color: #FFFFFF;
              padding: 8px 0;
              text-align: center;
              margin: 30px 0 48px 30px;
              &.trends3{
                background: #F18D28;
              }
              &.trends1{
                background: #00A0E9;
              }
              &.trends2{
                background: #52AD34;
              }
              &.trends4{
                background: #FFB800;
              }
            }
            .typeImg{
              width: 140px;
              height: 140px;
            }
            .title{
              font-size: 32px;
              font-weight: bold;
              color: #333333;
              margin: 20px 0;
            }
            .remark{
              text-align: left;
              width: 260px;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 30px;
              margin:0 45px 35px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
            }
          }
          .right{
            background: #fff;
            border-radius:  0 10px 10px 0  ;
            width: 100%;
            padding: 60px 75px;
            .remarkImg{
              width: 700px;
              height: 394px;
            }
          }
        }
      }
      .more-box{
        background: #fff;
        text-align: center;
        padding: 20px 0;
        margin-bottom: 60px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
@media screen and (min-width:993px) and (max-width: 1199px){
  .smallImgBox{
    width: 100% !important;
    height: 300px !important;
  }
}
@media screen and (max-width: 1200px) {
  .educationPlatform{
    .eduIndex-pc{
      display: none;
    }
    .mobile-one{
      background: #fff;
      padding: 25px 15px;
      text-align: center;
      .title{
        font-size: 23px;
        font-weight: bold;
        color: #333333;
      }
      .sub-title{
        font-size: 13px;
        font-weight: 400;
        color: #FD9C02;
        margin-top: 5px;
      }
    }
    .mobile-two{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 15px 0 ;
      span{
        font-size: 19px;
        font-weight: bold;
        color: #333333;
      }
      .btnClick{
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        .moreImg{
          width: 7px;
          height: 8px;
          vertical-align: middle;
          margin-left: 6px;
        }
      }
    }
    .mobile-three{
      .item{
        background: #fff;
        border-radius: 10px;
        margin: 15px 15px 20px;
        padding: 20px;
        .three-top-box{
          display: flex;
          align-items: center;
          .bigImgBox{
            width: 72px;
            height: 72px;
            margin-right: 20px;
          }
          .right{
            width: 80%;
            .title{
              font-size: 19px;
              font-weight: bold;
              color: #333333;
            }
            .type-btn{
              width: 80px;
              border-radius: 4px ;
              text-align: center;
              padding: 4px 0;
              font-size: 12px;
              font-weight: 400;
              color: #FFFFFF;
              margin-top: 5px;
              &.trends3{
                background: #F18D28;
              }
              &.trends1{
                background: #00A0E9;
              }
              &.trends2{
                background: #52AD34;
              }
              &.trends4{
                background: #FFB800;
              }
            }
          }
        }
        .smallImgBox{
          width: 100%;
          height: 172px;
          margin: 15px 0;
        }
        .desc{
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
